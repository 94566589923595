import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '@/views/Home'
// import OrderConfirmation from '@/views/OrderConfirmation'
// import PayOffer from '@/views/PayOffer'
// import ThankYouLanding from '@/views/ThankYouLanding'
// import ExternalOrder from '@/views/ExternalOrder'
// import Product from '@/views/Product.vue'

// import Login from '@/views/Login'
// import Signup from '@/views/Signup'
// import ForgotPassword from '@/views/ForgotPassword'
// import ResetPassword from '@/views/ResetPassword'
// import LoginOrSignupEvent from '@/views/LoginOrSignupEvent'
// import EventMenu from '@/views/EventMenu.vue'

// import LoginAsUser from '@/views/LoginAsUser'

// import UserArea from '@/views/UserArea'
// import UserAddresses from '@/views/UserAddresses'
// import UserAddressesEdit from '@/views/UserAddressesEdit'
// import UserContact from '@/views/UserContact'
// import UserInvoiceDetails from '@/views/UserInvoiceDetails'
// import UserLogout from '@/views/UserLogout'
// import UserPayments from '@/views/UserPayments'
// import UserProfile from '@/views/UserProfile'
// import UserSubscriptions from '@/views/UserSubscriptions'
// import UserSubscriptionDetails from '@/views/UserSubscriptionDetails'
// import UserSubscriptionEdit from '@/views/UserSubscriptionEdit'

// import AddcardVivaSuccess from '@/views/AddcardVivaSuccess.vue'
// import AddcardVivaFailure from '@/views/AddcardVivaFailure.vue'

// import Dev from '@/views/Dev.vue'
// import Dev404 from '@/views/Dev404.vue'
// import DevAccessDenied from '@/views/DevAccessDenied.vue'


const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},{
		path: '/:pathMatch(.*)*',
		name: 'Home',
		component: Home,
	}
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	component: Home,
	// 	meta: { orderFlow: true },
	// 	children: [
	// 		{
	// 			path: 'postal_code',
	// 			name: 'PostalCode',
	// 			component: Home,
	// 		},
	// 		{
	// 			path: 'order_type',
	// 			name: 'OrderType',
	// 			component: Home,
	// 		},
	// 		{
	// 			path: 'plan',
	// 			name: 'Plan',
	// 			component: Home
	// 		},
	// 		{
	// 			path: 'delivery_days',
	// 			name: 'DeliveryDays',
	// 			component: Home
	// 		},
	// 		{
	// 			path: 'combo',
	// 			name: 'Combo',
	// 			component: Home
	// 		},
	// 		{
	// 			path: 'meals_first',
	// 			name: 'MealsFirst',
	// 			component: Home
	// 		},
	// 		{
	// 			path: 'meals_second',
	// 			name: 'MealsSecond',
	// 			component: Home
	// 		},
	// 		{
	// 			path: 'login_or_signup',
	// 			name: 'LoginOrSignup',
	// 			component: Home
	// 		},
	// 		{
	// 			path: 'addresses',
	// 			name: 'Addresses',
	// 			component: Home
	// 		},
	// 		{
	// 			path: 'payment_method',
	// 			name: 'PaymentMethod',
	// 			component: Home
	// 		},
	// 	]
	// },
	// {
	// 	path: '/:pathMatch(.*)*',
	// 	component: Dev404
	// },
	// {
	// 	path: '/confirmation/:status',
	// 	name: 'Confirmation',
	// 	component: OrderConfirmation,
	// 	props: true
	// },
	// {
	// 	path: '/landing_payment/:token',
	// 	name: 'PayOffer',
	// 	component: PayOffer,
	// 	props: true
	// },
	// {
	// 	path: '/landing/:action/:result',
	// 	name: 'PaymentLandingStatus',
	// 	component: ThankYouLanding,
	// 	props: true
	// },
	// {
	// 	path: '/external_order/:combo_id',
	// 	name: 'ExternalOrder',
	// 	component: ExternalOrder,
	// 	props: true
	// },
	// {
	// 	path: '/product/:id',
	// 	name: 'Product',
	// 	component: Product,
	// 	props: true
	// },
	// {
	// 	path: '/login',
	// 	name: 'Login',
	// 	component: Login
	// },
	// {
	// 	path: '/signup',
	// 	name: 'Signup',
	// 	component: Signup
	// },
	// {
	// 	path: '/event/:event_id',
	// 	name: 'LoginOrSignupEvent',
	// 	component: LoginOrSignupEvent,
	// 	props: true
	// },
	// {
	// 	path: '/forgot_password',
	// 	name: 'ForgotPassword',
	// 	component: ForgotPassword
	// },
	// {
	// 	path: '/reset_password/:token',
	// 	name: 'ResetPassword',
	// 	component: ResetPassword,
	// 	props: true
	// },
	// {
	// 	path: '/login_as_user',
	// 	name: 'LoginAsUser',
	// 	component: LoginAsUser
	// },
	// {
	// 	path: '/user',
	// 	name: 'UserArea',
	// 	component: UserArea,
	// 	redirect: '/user/profile',
	// 	children: [
	// 		{
	// 			path: 'profile',
	// 			name: 'UserProfile',
	// 			component: UserProfile
	// 		},
	// 		{
	// 			path: 'subscriptions',
	// 			name: 'UserSubscriptions',
	// 			component: UserSubscriptions
	// 		},
	// 		{
	// 			path: 'subscriptions/:subscription_id',
	// 			name: 'UserSubscriptionDetails',
	// 			component: UserSubscriptionDetails,
	// 			props: true
	// 		},
	// 		{
	// 			path: 'subscriptions/:subscription_id/:token',
	// 			name: 'UserSubscriptionDetailsToken',
	// 			component: UserSubscriptionDetails,
	// 			props: true
	// 		},
	// 		{
	// 			path: 'subscriptions/:subscription_id/edit/:delivery',
	// 			name: 'UserSubscriptionEdit',
	// 			component: UserSubscriptionEdit,
	// 			props: true
	// 		},
	// 		{
	// 			path: 'addresses',
	// 			name: 'UserAddresses',
	// 			component: UserAddresses
	// 		},
	// 		{
	// 			path: 'addresses/:address_id',
	// 			name: 'UserAddressesEdit',
	// 			component: UserAddressesEdit,
	// 			props: true
	// 		},
	// 		{
	// 			path: 'payments',
	// 			name: 'UserPayments',
	// 			component: UserPayments
	// 		},
	// 		{
	// 			path: 'invoice_details',
	// 			name: 'UserInvoiceDetails',
	// 			component: UserInvoiceDetails
	// 		},
	// 		{
	// 			path: 'contact',
	// 			name: 'UserContact',
	// 			component: UserContact
	// 		},
	// 		{
	// 			path: 'logout',
	// 			name: 'UserLogout',
	// 			component: UserLogout
	// 		}
	// 	]
	// },
	// {
	// 	path: '/addcard/viva/success',
	// 	name: 'AddcardVivaSuccess',
	// 	component: AddcardVivaSuccess
	// },
	// {
	// 	path: '/addcard/viva/failure',
	// 	name: 'AddcardVivaFailure',
	// 	component: AddcardVivaFailure
	// },
	// {
	// 	path: '/dev',
	// 	name: 'Dev',
	// 	component: Dev
	// },
	// {
	// 	path: '/dev404',
	// 	name: 'Dev404',
	// 	component: Dev404
	// },
	// {
	// 	path: '/devaccessdenied',
	// 	name: 'DevAccessDenied',
	// 	component: DevAccessDenied
	// },
	// {
	// 	path: '/eventmenu/athens-throwdown-2022',
	// 	name: 'EventMenu',
	// 	component: EventMenu
	// }
]


const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
