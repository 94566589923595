<template>
	<div class="">
		<router-view/>
	</div>
</template>

<style lang="scss">
#app {
}

#nav {
}
</style>
