import { createStore } from 'vuex'
import { DateTime } from 'luxon'
import {
	assign,
	filter,
	find,
	findIndex,
	flatMap,
	get,
	map,
	min,
	pick,
	reduce,
	some,
	sumBy,
	uniqBy
} from 'lodash'

import flows from '@/flows'

import { decrypt, decryptLanded } from '@/services/auth'

import { takeAwayAddress } from '@/services/constants'

import {
	formatPrice,
	formatAddressLabel,
	formatAddressUserLabel,
	formatCreditCardLabel,
	setMealSortId,
	getStorageItem,
	setStorageItem,
	uuid,
	weekdayToNumber
} from '@/services/utils'

import {
	calcDeliveryDaysMealCount,
	formatAdhocSubscription,
	formatDataFromCharge,
	formatSubscription,
	recalcSubscriptionProps,
	setWeeklyComboMeals,
	setMonthlyComboMeals
} from '@/services/subscription'

import {
	fetchCombos,
	fetchComboDetails,
	fetchPostalCodes,
	fetchProducts,
	fetchDeliveryFees,
	userJSON
} from '@/services/storeAPI'

import { sendComboProductsAddToCart } from '@/services/googleAnalytics'

let userAPI = null

export default createStore({
	state: {
		orderType: 'weekly',
		steps: flows.weekly,
		currentStepIndex: 0,
		formerStepIndex: 0,
		postalCodes: [],
		availableCombos: [],
		availableMeals: [],
		tags: [],
		deliveryFees: [],
		mealsPerDay: null,
		daysPerWeek: null,
		deliveryDaysMealCount: [],
		discount: 0,
		coupon: null,
		deliveryDays: null,
		deliveryStart: null,
		deliveryTime: null,
		deliveryDates: [],
		displayedWeek: 1,
		deliveryAddresses: [],
		selectedCombo: {},
		selectedMeals: [],
		customSubscription: true,
		paymentMethod: 'card',
		userPostalCode: {},
		user: getStorageItem('state.user') || {},
		userDiscount: 0,
		userFirstAdhocDiscount: 0,
		userAddresses: [],
		userCreditCards: [],
		creditCard: null,
		acceptedTerms: false,
		accessToken: getStorageItem('state.accessToken') || null,
		queryToken: getStorageItem('state.queryToken') || null,
		warning: false,
		userSubscription: {},
		externalOrder: false,
		transitioning: false,
		loading: false,
		finalPrice:[]
	},
	getters: {
		getFinalPrice(state){
			return state.finalPrice
		},
		userInCloseRange(state){
			return state.userPostalCode.delivery_service_id === 0
		},
		userInFarRange(state){
			return state.userPostalCode.delivery_service_id && state.userPostalCode.delivery_service_id !== 0
		},
		currentStep(state){
			return state.steps[state.currentStepIndex]
		},
		totalSteps(state){
			return state.steps.length
		},
		movedBack(state){
			return state.currentStepIndex < state.formerStepIndex
		},
		movedForward(state){
			return state.currentStepIndex > state.formerStepIndex
		},
		adhocOrder(state){
			return state.orderType === 'adhoc'
		},
		weeklyOrder(state){
			return state.orderType === 'weekly'
		},
		monthlyOrder(state){
			return state.orderType === 'monthly'
		},
		mealsPerWeek(state){
			return state.mealsPerDay * state.daysPerWeek
		},
		deliveryFee(state, getters){
			const feeId = getters.userInCloseRange ? -1 : -2
			return find(state.deliveryFees, ['id', feeId])
		},
		loyaltyPlanDiscount(state){
			return state.orderType === 'adhoc'
				? Math.max(
						state.discount,
						state.userDiscount,
						state.userFirstAdhocDiscount
					)
				: Math.max(state.discount, state.userDiscount)
		},
		maxDiscount(state){
			return state.orderType === 'adhoc'
				? Math.max(
						state.discount,
						state.userDiscount,
						state.userFirstAdhocDiscount
					)
				: Math.max(
						state.discount,
						state.userDiscount,
						get(state, 'selectedCombo.discount_percentage', 0)
					)
		},
		weeklyTotalCostEstimation(state, getters){
			return typeof getters.mealsPerWeek === 'number'
				? formatPrice(getters.mealsPerWeek * 6, 0)
				: null
		},
		customComboSelected(state){
			return state.selectedCombo.id === 'custom'
		},
		oneDeliveryDate(state){
			return state.deliveryDates.length === 1
		},
		firstDeliveryDay(state, getters){
			return getters.adhocOrder
				|| getters.weeklyOrder && state.currentStepIndex === 5
				|| getters.monthlyOrder && state.currentStepIndex === 4
		},
		deliveryDate(state, getters){
			let index = getters.firstDeliveryDay ? 0 : 1

			if(getters.monthlyOrder){
				// browse two delivery dates for each delivery week
				// for users in close range locations,
				// and one delivery date per delivery week
				// for users in far range locations
				// Increment can be equal to 2 or 1 repsectively
				const increment = state.deliveryDates.length / 4
				// for users in far range locations,
				// index's initial value will always be 0
				// since there is only one delivery date per week,
				// hence deliveryDates will be traversed one by one
				index = (increment * (state.displayedWeek - 1)) + index
			}

			return state.deliveryDates[index]
		},
		displayedWeekDeliveryDates(state, getters){
			if(!getters.monthlyOrder) return state.deliveryDates

			const increment = state.deliveryDates.length / 4
			const firstDateIndex = increment * (state.displayedWeek - 1)
			const sliceEndIndex = firstDateIndex + increment
			return state.deliveryDates.slice(firstDateIndex, sliceEndIndex)
		},
		firstDeliveryInWeekdayOrder(state, getters){
			if(getters.oneDeliveryDate || !state.deliveryDays) return true

			// patch for uninitialized deliveryDays prop
			if(!getters.deliveryDate) return false

			const deliveryWeekdays = state.deliveryDays.split(',').map(d => d * 1)
			return getters.deliveryDate.weekday === min(deliveryWeekdays)
		},
		deliveryDayMealsCount(state, getters){
			// In adhoc orders, user can select any number of meals
			if(state.adhocOrder) return 0

			if(getters.weeklyOrder){
				// In weekly orders with only one delivery day
				// the total of meals per week is assigned to it
				if(getters.oneDeliveryDate || !state.deliveryDays) return getters.mealsPerWeek

				const deliveryWeekdays = state.deliveryDays
					.split(',')
					.map(d => d * 1)
				const interval = deliveryWeekdays[1] - deliveryWeekdays[0]

				if(getters.firstDeliveryInWeekdayOrder){
					// first delivery weekday includes number of meals per day
					// until the day before the second delivery day
					return interval * state.mealsPerDay
				} else {
					// second delivery weekday includes number of meals per day
					// for the rest of the days
					return getters.mealsPerWeek - (interval * state.mealsPerDay)
				}
			} else if(getters.monthlyOrder){
				// patch for uninitialized deliveryDays prop
				if(!getters.deliveryDate) return 0

				const week = state.displayedWeek.toString()
				const weekMealsCountObj = find(state.deliveryDaysMealCount, ['week', week])
				const weekMealsCountArr = get(weekMealsCountObj, 'mealsCountPerDay', [])
				const deliveryWeekday = getters.deliveryDate.weekday
				const obj = find(weekMealsCountArr, ['deliveryWeekday', deliveryWeekday])
				return get(obj, 'mealsCount', 0)
			}
		},
		deliveryDaySelectedMeals(state, getters){
			const deliveryDate = getters.deliveryDate

			if(!deliveryDate) return []

			return filter(state.selectedMeals, ['deliveryTs', deliveryDate.timestamp])
		},
		deliveryDaySelectedMealsCount(state, getters){
			return getters.deliveryDaySelectedMeals.length
		},
		completedDeliveryDayMealsSelection(state, getters){
			return getters.deliveryDaySelectedMealsCount >= getters.deliveryDayMealsCount
		},
		deliveryDaySelectedMealsPrice(state, getters){
			const sumProp = getters.maxDiscount ? 'discountedPrice' : 'price'
			const mealsPrice = sumBy(getters.deliveryDaySelectedMeals, sumProp)
			return formatPrice(mealsPrice)
		},
		deliveryDaySelectedMealsOriginalPrice(state, getters){
			const mealsPrice = sumBy(getters.deliveryDaySelectedMeals, 'price')
			return formatPrice(mealsPrice)
		},
		deliveryDayDiscountAmount(state, getters){
			const mealsPrice = sumBy(getters.deliveryDaySelectedMeals, 'price')
			const discountedMealsPrice = sumBy(getters.deliveryDaySelectedMeals, 'discountedPrice')
			return formatPrice(mealsPrice - discountedMealsPrice)
		},
		availableAddresses(state, getters){
			const userPostalCodeGroupID =
				get(state.userPostalCode, 'postal_code_group', null) ||
				get(state.userPostalCode, 'id', null)

			const parentPostalCode = find(state.postalCodes, ['id', userPostalCodeGroupID])
			const groupPostalCodes = [
				...filter(state.postalCodes, ['postal_code_group', userPostalCodeGroupID]),
				parentPostalCode
			]

			const availableAddresses = filter(state.userAddresses, address => {
				return find(groupPostalCodes, ['postal_code', address.postal_code])
			})

			return getters.userInCloseRange
				? [ ...availableAddresses, takeAwayAddress ]
				: availableAddresses
		},
		properPaymentMethod(state){
			return state.paymentMethod === 'cash' || get(state.creditCard, 'id')
		},
		issuesInvoice(state){
			const invoiceDetailsProps = [
				'comp_name',
				'comp_type',
				'comp_address',
				'comp_afm',
				'comp_tax_office',
				'comp_phone'
			]

			const userInvoiceDetails = pick(state.user, invoiceDetailsProps)

			return reduce(userInvoiceDetails, (result, value) => {
				return result && value
			}, true)
		}
	},
	mutations: {
		setPostalCodes(state, postalCodes){
			state.postalCodes = postalCodes
		},
		setUserPostalCode(state, postalCode){
			state.userPostalCode = postalCode
		},
		setOrderType(state, value){
			state.orderType = value
		},
		setSteps(state, orderType){
			state.steps = flows[orderType]
		},
		setCurrentStepIndex(state, index){
			state.currentStepIndex = index
		},
		setFormerStepIndex(state, index){
			state.formerStepIndex = index
		},
		setAvailableCombos(state, combos){
			state.availableCombos = combos
		},
		setAvailableMeals(state, meals){
			state.availableMeals = meals
		},
		setMealTags(state, tags){
			state.tags = tags
		},
		setDeliveryFees(state, fees){
			state.deliveryFees = fees
		},
		setMealsPerDay(state, value){
			state.mealsPerDay = value
		},
		setDaysPerWeek(state, value){
			state.daysPerWeek = value
		},
		setDeliveryDaysMealCount(state, payload){
			state.deliveryDaysMealCount = payload
		},
		setDiscount(state, value){
			state.discount = value
		},
		setCoupon(state, coupon){
			state.coupon = coupon
		},
		setDeliveryDays(state, value){
			state.deliveryDays = value
		},
		setDeliveryStart(state, value){
			state.deliveryStart = value
		},
		setDeliveryTime(state, value){
			state.deliveryTime = value
		},
		setDeliveryDates(state, dates){
			state.deliveryDates = dates
		},
		setSelectedCombo(state, value){
			state.selectedCombo = value
		},
		setDisplayedWeek(state, value){
			state.displayedWeek = value
		},
		addMeal(state, product){
			// define meal priority for proper sorting
			// in selected meals list
			// and removal uid for effectively removing it
			const extendedMeal = {
				...product,
				quantity: 1,
				sortId: setMealSortId(state, product),
				removalUid: uuid()
			}

			state.selectedMeals.push(extendedMeal)
		},
		addAnotherMeal(state, { product, productIdx }){
			state.selectedMeals.splice(productIdx, 0, product)
		},
		removeMeal(state, productIdx){
			state.selectedMeals.splice(productIdx, 1)
		},
		setSelectedMeals(state, products){
			const meals = map(products, product => {
				return {
					...product,
					sortId: setMealSortId(state, product),
					removalUid: uuid()
				}
			})

			state.selectedMeals = meals
		},
		emptySelectedMeals(state){
			state.selectedMeals = []
		},
		setCustomSubscription(state, value){
			state.customSubscription = value
		},
		setUser(state, user){
			state.user = user
			setStorageItem('state.user', user)
		},
		setAccessToken(state, token){
			state.accessToken = token
			setStorageItem('state.accessToken', token)
		},
		setQueryToken(state, token){
			state.queryToken = token
			setStorageItem('state.queryToken', token)
		},
		setUserDiscount(state, value){
			state.userDiscount = value
		},
		setUserFirstAdhocDiscount(state, value){
			state.userFirstAdhocDiscount = value
		},
		setUserAddresses(state, addresses){
			state.userAddresses = addresses
		},
		updateUserAddress(state, address){
			const addressIndex = findIndex(state.userAddresses, ['id', address.id])
			if(addressIndex > -1){
				state.userAddresses.splice(addressIndex, 1, address)
			} else {
				state.userAddresses.push(address)
			}
		},
		setDeliveryAddresses(state, payload){
			// payload must be an array
			// of { address, take_away, timestamp, weekday } objects
			// where 'address' is an address ID hash
			// and 'take_away' a boolean indicator

			// assign address by timestamp in all flows
			const keyProp = 'timestamp'

			state.deliveryDates = map(state.deliveryDates, dd => {
				const { address, take_away } = find(payload, [keyProp, dd[keyProp]])
				return { ...dd, address, take_away }
			})
		},
		setPaymentMethod(state, method){
			state.paymentMethod = method
		},
		setUserCreditCards(state, cards){
			state.userCreditCards = cards
		},
		setCreditCard(state, card){
			state.creditCard = card
		},
		addCreditCard(state, card){
			state.userCreditCards.push(card)
		},
		setDefaultCreditCard(state, cardID){
			for(let i = 0; i < state.userCreditCards.length; i++){
				state.userCreditCards[i].default = state.userCreditCards[i].id === cardID
			}
		},
		removeCreditCard(state, cardID){
			const index = findIndex(state.userCreditCards, ['id', cardID])
			state.userCreditCards.splice(index, 1)
		},
		setAcceptedTerms(state, value){
			state.acceptedTerms = value
		},
		setWarning(state, warning){
			state.warning = warning
		},
		setUserSubscription(state, subscription){
			state.userSubscription = subscription
		},
		addMealToUserSubscription(state, meal){
			// add the meal to products of week that includes meal delivery timestamp
			const productsPerWeek = state.userSubscription.productsPerWeek
			// find the week according to meal delivery timestamp
			const weekIndex = findIndex(productsPerWeek, w => {
				return some(w.products, p => {
					return DateTime
						.fromISO(p.delivery_date)
						.toMillis() === meal.deliveryTs
				})
			})

			// if no week is suitable, don't add the meal
			if(weekIndex === -1) return

			// format the product object that will be added to the subscription
			// with the help of a random product with same delivery date
			const referenceProduct = find(productsPerWeek[weekIndex].products, p => {
				return DateTime
					.fromISO(p.delivery_date)
					.toMillis() === meal.deliveryTs
			})

			//  Add discount on product from User Subscription discount_percentage
            referenceProduct.discount = state.userSubscription.discount_percentage

			const product = assign({}, referenceProduct, {
				id: meal.id,
				name: meal.name,
				unit_price: meal.price,
				price: meal.discountedPrice,
				environment_tax_price: meal.environment_tax_price
			})

			// add formatted product to userSubscription
			state.userSubscription.productsPerWeek[weekIndex].products.push(product)
		},
		removeMealFromUserSubscription(state, meal){
			const productsPerWeek = state.userSubscription.productsPerWeek
			// find the week according to meal delivery timestamp and product id
			const weekIndex = findIndex(productsPerWeek, w => {
				return some(w.products, p => {
					const productDelTs = DateTime
						.fromISO(p.delivery_date)
						.toMillis()
					return productDelTs === meal.deliveryTs && p.id === meal.id
				})
			})

			// if no week is suitable, don't remove the meal
			if(weekIndex === -1) return

			// find the product's index and remove it in a reactive way
			const weekProducts = productsPerWeek[weekIndex].products
			const productIndex = findIndex(weekProducts, p => {
				const productDelTs = DateTime
					.fromISO(p.delivery_date)
					.toMillis()
				return productDelTs === meal.deliveryTs && p.id === meal.id
			})

			state.userSubscription.productsPerWeek[weekIndex].products.splice(productIndex, 1)
		},
		changeAddressToUserSubscription(state, payload){
			// payload must be an { address, timestamp } object
			// where 'address' is an address ID hash
			const { address, timestamp } = payload
			const deliveryWeekday = DateTime.fromMillis(timestamp).weekday
			const subscriptionWeeks = state.userSubscription.productsPerWeek

			const weekIndex = findIndex(subscriptionWeeks, w => {
				const weekStartTs = DateTime.fromFormat(w.period.start, 'dd-MM-yyyy').toMillis()
				const weekEndTs = DateTime.fromFormat(w.period.end, 'dd-MM-yyyy').toMillis()
				return timestamp >= weekStartTs && timestamp < weekEndTs
			})

			const ddayIndex = findIndex(subscriptionWeeks[weekIndex].delivery_days, dd => {
				return Number.isNaN(dd.weekday)
					? weekdayToNumber(dd.weekday) === deliveryWeekday
					: dd.weekday === deliveryWeekday
			})

			state.userSubscription.productsPerWeek[weekIndex].delivery_days[ddayIndex].address = address
		},
		changeUserSubscriptionProperty(state, payload){
			// payload must be a { prop, value } object
			const { prop, value } = payload
			state.userSubscription[prop] = value
		},
		setExternalOrder(state, value){
			state.externalOrder = value
		},
		setTransitioning(state, value){
			state.transitioning = value
		},
		setLoading(state, value){
			state.loading = value
		}
	},
	actions: {
		async getPostalCodes({ commit }){
			try {
				const response = await fetchPostalCodes()
				// Filter postal codes that exist in DB, because only the following
				// have available delivery service at the moment.
				const filteredPostalCodes = filter(response.results, (pc) =>
					pc.id === 12 || pc.id === 267 ||
					pc.postal_code_group === 12 || pc.postal_code_group === 267
				)
				commit('setPostalCodes', filteredPostalCodes)
			} catch(e){
				console.error(e)
			}
		},
		setOrderTypeSteps({ commit }, orderType){
			commit('setOrderType', orderType)
			commit('setSteps', orderType)
			commit('setDeliveryStart', null)
			commit('setMealsPerDay', null)
			commit('setDaysPerWeek', null)
			commit('setSelectedCombo', {})
			commit('setDiscount', 0)
			commit('emptySelectedMeals')
			commit('setExternalOrder', false)
			commit('setWarning', false)
		},
		stepForward({ state, getters, commit }){
			if(state.currentStepIndex < getters.totalSteps - 1){
				commit('setFormerStepIndex', state.currentStepIndex)
				commit('setCurrentStepIndex', state.currentStepIndex + 1)
			}
		},
		stepBackwards({ state, commit }){
			if(state.currentStepIndex > 0){
				commit('setFormerStepIndex', state.currentStepIndex)
				commit('setCurrentStepIndex', state.currentStepIndex - 1)
			}
		},
		goToStep({ state, getters, commit }, stepNo){
			if(get(getters.currentStep, 'no') === stepNo) return;

			const stepIndex = stepNo - 1
			if(stepIndex >= 0 && stepIndex < getters.totalSteps){
				commit('setFormerStepIndex', state.currentStepIndex)
				commit('setCurrentStepIndex', stepIndex)
			}
		},
		async getAvailableCombos({ commit }){
			try {
				const response = await fetchCombos()
				commit('setAvailableCombos', response.results)
			} catch(e){
				console.error(e)
			}
		},
		async getAvailableMealsWithTags({ commit }){
			try {
				const response = await fetchProducts()
				const availableMeals = uniqBy(response.results, 'id')
				commit('setAvailableMeals', availableMeals)
				commit('setMealTags', response.tags)
			} catch(e){
				console.error(e)
			}
		},
		async getDeliveryFees({ commit }){
			try {
				const response = await fetchDeliveryFees()
				commit('setDeliveryFees', response.result)
			} catch(e){
				console.error(e)
			}
		},
		async setComboMeals({ state, getters, commit }, combo){
			try {
				// set selected combo in state
				commit('setSelectedCombo', combo)

				// fetch combo details
				const response = await fetchComboDetails(combo.id)

				// set combo meals in state
				// with delivery day timestamp and numeric weekday,
				// discount prices
				// and quantity
				const meals = combo.combo_type === 'weekly'
					? setWeeklyComboMeals(response.results, state, getters)
					: setMonthlyComboMeals(response.results, state, getters)

				commit('setSelectedMeals', meals)
				commit('setCustomSubscription', false)

				// push GA event for every product in combo
				sendComboProductsAddToCart(meals)

				if(combo.combo_type === 'monthly'){
					// calculate meals per day and days per week
					// from combo meals of every week
					const payload = calcDeliveryDaysMealCount(meals)
					commit('setDeliveryDaysMealCount', payload)
				}
			} catch(e){
				console.error(e)
			}
		},
		loginUser({ commit }, { userToken, accessToken }){
			if(!(userToken && accessToken)) return

			commit('setAccessToken', accessToken)
			const user = JSON.parse(decrypt(userToken))
			commit('setUser', user)
			userAPI = userJSON({ accessToken })
		},
		loginLandedUser({ commit }, queryToken){
			if(!queryToken) return

			commit('setQueryToken', queryToken)
			const { customer_id } = JSON.parse(decryptLanded(queryToken))
			commit('setUser', { id: customer_id })
			userAPI = userJSON({ queryToken })
		},
		generateUserAPI({ state }){
			const { accessToken, queryToken } = state
			if(accessToken){
				userAPI = userJSON({ accessToken })
				return true
			} else if(queryToken){
				userAPI = userJSON({ queryToken })
				return true
			} else {
				return false
			}
		},
		resetState({ commit, dispatch }){
			commit('setFormerStepIndex', 0)
			commit('setCurrentStepIndex', 0)
			dispatch('setOrderTypeSteps', 'weekly')
			commit('setMealsPerDay', null)
			commit('setDaysPerWeek', null)
			commit('setDeliveryDays', null)
			commit('setDeliveryStart', null)
			commit('setDeliveryTime', null)
			commit('setDiscount', 0)
			commit('setUserDiscount', 0)
			commit('setUserFirstAdhocDiscount', 0)
			commit('setCoupon', null)
			commit('emptySelectedMeals')
			commit('setUserSubscription', {})
			commit('setCustomSubscription', true)
			commit('setExternalOrder', false)
		},
		logoutUser({ commit, dispatch }){
			commit('setAccessToken', null)
			commit('setQueryToken', null)
			commit('setUser', {})
			dispatch('resetState')
			userAPI = null
		},
		async getUserDiscount({ state, commit }){
			try {
				const userID = state.user.id
				if(userID){
					const {
						loyaltyDiscount,
						firstOnlyLoyaltyDiscount
					} = await userAPI.fetchDiscount(userID)

					commit('setUserDiscount', loyaltyDiscount)
					commit('setUserFirstAdhocDiscount', firstOnlyLoyaltyDiscount)
				}
			} catch(e){
				console.error(e)
			}
		},
		async getUserAddresses({ state, commit }){
			try {
				const userID = state.user.id
				if(userID){
					const response = await userAPI.fetchAddresses(userID)
					const addresses = map(response.results, (address) => {
						return {
							...address,
							label: formatAddressLabel(address),
							userLabel: formatAddressUserLabel(address),
							postal_code: address.address_zip.replace(' ', '')
						}
					})
					commit('setUserAddresses', addresses)
					return addresses
				}
			} catch(e){
				console.error(e)
			}
		},
		async createUserAddress({ state, commit }, address){
			try {
				const userID = state.user.id
				if(userID){
					const addressForDB = {
						...address,
						customer_id: userID
					}
					const savedAddress = await userAPI.createAddress(addressForDB)

					if(savedAddress && savedAddress.id){
						savedAddress.label = formatAddressLabel(savedAddress)
						savedAddress.userLabel = formatAddressUserLabel(savedAddress)
						savedAddress.postal_code = savedAddress.address_zip.replace(' ', '')
						commit('updateUserAddress', savedAddress)
					}
					return savedAddress
				}
			} catch(e){
				console.error(e)
			}
		},
		async updateUserAddress({ state, commit }, address){
			try {
				const userID = state.user.id
				if(userID){
					const addressForDB = {
						...address,
						customer_id: userID
					}
					const savedAddress = await userAPI.updateAddress(addressForDB)
					if(savedAddress && savedAddress.id){
						savedAddress.label = formatAddressLabel(savedAddress)
						savedAddress.userLabel = formatAddressUserLabel(savedAddress)
						savedAddress.postal_code = savedAddress.address_zip.replace(' ', '')
						commit('updateUserAddress', savedAddress)
						return savedAddress
					}
				}
			} catch(e){
				console.error(e)
			}
		},
		async getUserCreditCards({ state, commit }){
			try {
				const userID = state.user.id
				if(userID){
					const response = await userAPI.fetchCreditCards(userID)
					const creditCards = map(response.results, (card) => {
						return {
							...card,
							label: formatCreditCardLabel(card)
						}
					})
					commit('setUserCreditCards', creditCards)
					return creditCards
				}
			} catch(e){
				console.error(e)
			}
		},
		async saveUserCreditCard({ state, commit }, card){
			try {
				const userID = state.user.id
				if(userID){
					const savedCard = await userAPI.addCreditCard(card, userID)
					if(savedCard && savedCard.id){
						savedCard.label = formatCreditCardLabel(savedCard)
						commit('setCreditCard', savedCard)
						commit('addCreditCard', savedCard)
						commit('setDefaultCreditCard', savedCard.id)
					}
					return savedCard
				}
			} catch(e){
				console.error(e)
			}
		},
		async newUserCreditCard({ state }){
			try {
				const userID = state.user.id
				if(userID){
					const response = await userAPI.newCreditCard(userID)

					return get(response, 'url')
				}
			} catch(e){
				console.error(e)
			}
		},
		async setUserDefaultCreditCard({ state, commit }, cardID){
			if(!cardID) return

			try {
				const userID = state.user.id
				if(userID){
					const { statusCode, defaultCardId } = await userAPI.setDefaultCreditCard(cardID, userID)

					if(statusCode === 200 && defaultCardId){
						commit('setDefaultCreditCard', defaultCardId)
					}
				}
			} catch(e){
				console.error(e)
			}
		},
		async deleteUserCreditCard({ state, commit }, cardID){
			if(!cardID) return

			try {
				const userID = state.user.id
				if(userID){
					const { statusCode, deletedCardId, defaultCardId } = await userAPI.deleteCreditCard(cardID, userID)

					if(statusCode === 200 && deletedCardId){
						commit('removeCreditCard', deletedCardId)

						if(defaultCardId){
							commit('setDefaultCreditCard', defaultCardId)
						}
					}

					return { statusCode, defaultCardId }
				}
			} catch(e){
				console.error(e)
				return e
			}
		},
		async takeFinalPrice({ state, getters }){
			const subscription = getters.adhocOrder
			? await formatAdhocSubscription(state, getters)
			: await formatSubscription(state, getters)
			state.finalPrice = subscription
		},
		async sendOrder({ state, getters, commit }){
			try {
				var οrderResult = false

				const subscription = getters.adhocOrder
					? formatAdhocSubscription(state, getters)
					: formatSubscription(state, getters)

				const userID = state.user.id

				const { statusCode, id, transaction_id } = await userAPI.submitOrder(subscription, userID)

				// add transaction_id to subscription
				// for use in GA analytics event
				subscription.transaction_id = transaction_id

				if(statusCode === 200){
					οrderResult = true

					if(subscription.payment_type === 'card'){
						const chargeData = formatDataFromCharge(state, id)
						// console.log("CHARGE DATA", chargeData)

						const response = await userAPI.chargeCard(chargeData)
						// console.log("CHARGE RESPONSE", response)

						// set result flag according to payment response
						οrderResult = response.charge.success

						// add charge data to subscription
						// for use in GA analytics event
						subscription.chargeResponse = response
					}

					// save subscription to store
					// for GA analytics event
					commit('setUserSubscription', subscription)
				}

				if(οrderResult === true){
					// consume coupon if there is one
					if(state.coupon){
						const couponID = state.coupon
						await userAPI.consumeCoupon(userID, { couponID })
					}

					// send success email to user
					const sendEmailData = {
						customer_id: userID,
						subscription_id: id
					}

					await userAPI.orderCompleteEmail(sendEmailData)

					return "success"
				}
				else {
					return "failure"
				}
			} catch(e){
				return "failure"
			}
		},
		async payOffer(chargeData){
			try {
				var OrderResult = true
				const response = await userAPI.chargeCard(chargeData)

				if (response.success === true) {
					OrderResult = true
				} else {
					OrderResult = false
				}

				if (OrderResult === true){
					const sendEmailData = {
						customer_id: chargeData.customer_id,
						subscription_id: chargeData.subscription_id
					}

					await userAPI.orderCompleteEmail(sendEmailData)

					return 'success'
				}
				else {
					return 'failure'
				}
			} catch(e){
				console.error("chargeCard Error:",e)
				return 'failure'
			}
		},
		async saveUserProfile({ state, commit }, data){
			try {
				const userID = state.user.id
				if(userID){
					const newProfile = await userAPI.updateProfile(data, userID)
					if ( newProfile.errors ) return newProfile
					commit('setUser', newProfile)
					return newProfile
				}
			} catch(e){
				console.error(e)
			}
		},
		async changeUserPassword({ state, dispatch }, data){
			try {
				const userID = state.user.id
				if(userID){
					const response = await userAPI.changePassword(data, userID)

					if(response.statusCode === 200){
						// if request was successful,
						// replace user credentials directly
						const { userToken, accessToken } = response
						dispatch('loginUser', { userToken, accessToken })
						return { result: 'OK' }
					} else {
						// if there is an error, return the response
						// for possible UI hndling
						return response
					}
				}
			} catch(e){
				console.error(e)
			}
		},
		getUserSubscriptions({ state }, { limit, page }){
			const userID = state.user.id
			if(userID){
				// const user_id_test = 'b490a3fe-ac41-48e4-8f91-26b840d710b6'
				return userAPI.fetchSubscriptions(userID, limit, page)
			}

			return 'User not logged in'
		},
		async getSubscriptionDetails({ state, commit }, subscriptionID){
			try {
				const userID = state.user.id
				if(userID){
					// const user_id_test = 'b490a3fe-ac41-48e4-8f91-26b840d710b6'
					const subscription = await userAPI.fetchSubscriptionDetails(userID, subscriptionID)
					commit('setUserSubscription', subscription)
					commit('setOrderType', subscription.type)
					commit('setDiscount', subscription.discount_percentage)

					// set user postal code to get available addresses
					// that belong to the same postal code group
					const addressIDs = flatMap(subscription.productsPerWeek, week => {
						return map(week.delivery_days, 'address')
					})
					const firstAddressID = find(addressIDs, id => id !== takeAwayAddress.id)
					const firstAddress = find(state.userAddresses, ['id', firstAddressID]) || takeAwayAddress
					const postalCode = find(state.postalCodes, ['postal_code', firstAddress.postal_code])
					commit('setUserPostalCode', postalCode)

					return subscription
				}
			} catch(e){
				console.error(e)
			}
		},
		async updateSubscription({ state, getters, commit, dispatch }){
			try {
				// re-calculate subscription delivery fees and final price
				// according to changes in products
				if(!getters.deliveryFee){
					await dispatch('getDeliveryFees')
				}

				const userID = state.user.id

				const subscription = recalcSubscriptionProps(
					state.userSubscription,
					getters.deliveryFee,
					getters.userInCloseRange
				)
				const result = await userAPI.updateSubscription(subscription, userID)

				if(result.statusCode === 400) return 400

				commit('setUserSubscription', result)
				return true
			} catch(e){
				console.error(e)
			}
		},
		async updateSubscriptionStatus({ state, commit }){
			try {
				const userID = state.user.id
				const subscription = pick(state.userSubscription, ['id', 'status'])
				const updatedSubscription = await userAPI.updateSubscriptionStatus(subscription, userID)
				commit('setUserSubscription', updatedSubscription)
				return true
			} catch(e){
				console.error(e)
			}
		},
		getUserPayments({ state }, { limit, page }){
			const userID = state.user.id
			if(userID){
				// define one year date range for querying payments
				// starting from today
				const dateFormat = 'yyyy-MM-dd HH:mm:ss'
				const to_date = DateTime.now().endOf('day').toUTC().toFormat(dateFormat)
				const from_date = DateTime.now().startOf('day').toUTC().minus({ years: 1 }).toFormat(dateFormat)
				const query = {
					trx_type: 'credit',
					from_date,
					to_date,
					rows_limit: limit,
					rows_offset: (page - 1) * limit
				}
				return userAPI.fetchPayments(userID, query)
			}

			return 'User not logged in'
		},
		async searchCoupon({ state, commit }, couponName){
			const userID = state.user.id
			if(userID){
				try {
					const subType = state.orderType
					const response = await userAPI.searchCoupon(userID, { couponName, subType })

					if(response.statusCode === 200){
						commit('setCoupon', response.couponID)
						commit('setDiscount', get(response, 'couponBatch.value', 0))
					}

					return response.statusCode
				} catch(e){
					// console.log("e", JSON.stringify(e, null, 2))
					if(e.statusCode) return e.statusCode
				}
			}
		}
	},
	modules: {}
})
