import {
	flatten,
	get,
	groupBy,
	isArray,
	map,
	reduce,
	remove,
	sumBy,
	uniq
} from 'lodash'


const brand = 'Fitness Meals'


function calcTotalValue(products){
	let cartValue = reduce(products, (result, product) => {
		return result + (product.price * product.quantity)
	}, 0)

	return Math.round(cartValue * 100) / 100
}


export function pushEcommerceEvent(event){
	if(!isArray(window.dataLayer)) return

	window.dataLayer.push({ ecommerce: null })
	window.dataLayer.push(event)
}

export function pushFireRemarketingTagEvent(event, keepEcommerce){
	if(!isArray(window.dataLayer)) return

	if(!keepEcommerce) window.dataLayer.push({ ecommerce: null })

	window.dataLayer.push(event)
}


export function sendImpression(products){
	const impressions = map(products, (product, i) => {
		return {
			id: product.id,
			name: product.name,
			category: product.category_name,
			price: product.price,
			brand,
			position: i + 1
		}
	})

	if(!impressions.length) return

	const event = {
		event: 'impressions',
		ecommerce: {
			currencyCode: 'EUR',
			impressions: impressions
		}
	}

	pushEcommerceEvent(event)
}


export function sendClick(product, position){
	if(!product) return

	const event = {
		event: 'productClick',
		ecommerce: {
			click: {
				products: [{
					id: product.id,
					name: product.name,
					category: product.category_name,
					price: product.price,
					brand,
					position
				}]
			}
		},
		google_tag_params: {
			ecomm_prodid: product.id,
			ecomm_category: product.category_name
		}
	}

	pushEcommerceEvent(event)
}


export function sendProductDetailView(product){
	if(!product) return

	const event = {
		event: 'detail',
		ecommerce: {
			click: {
				products: [{
					id: product.id,
					name: product.name,
					category: product.category_name,
					price: product.price,
					brand
				}]
			}
		},
		google_tag_params: {
			ecomm_pagetype: "product",
			ecomm_prodid: product.id,
			ecomm_category: product.category_name
		}
	}

	pushEcommerceEvent(event)
}


export function sendProductAddToCart(product){
	if(!product) return

	const event = {
		event: 'addToCart',
		ecommerce: {
			currencyCode: 'EUR',
			add: {
				products: [{
					id: product.id,
					name: product.name,
					category: product.category_name,
					price: product.price,
					brand,
					quantity: 1
				}]
			}
		},
		google_tag_params: {
			ecomm_pagetype: "category",
			ecomm_prodid: product.id,
		},
	}

	pushEcommerceEvent(event)
}


export function sendProductRemoveFromCart(product){
	if(!product) return

	const event = {
		event: 'removeFromCart',
		ecommerce: {
			remove: {
				products: [{
					id: product.id,
					name: product.name,
					category: product.category_name,
					price: product.price,
					brand,
					quantity: 1
				}]
			}
		},
		google_tag_params: {
			ecomm_pagetype: "category",
			ecomm_prodid: product.id,
		},
	}

	pushEcommerceEvent(event)
}


export function sendComboProductsAddToCart(products){
	if(!products.length) return

	const groupedProducts = groupBy(products, 'id')

	const cartProducts = map(groupedProducts, arr => {
		const product = arr[0]
		const quantity = arr.length

		return {
			id: product.id,
			name: product.name,
			category: product.category_name,
			price: product.price,
			brand,
			quantity,
		}
	})

	const productIds = map(cartProducts, 'id')
	const cartValue = calcTotalValue(cartProducts)

	const event = {
		event: 'addToCart',
		ecommerce: {
			currencyCode: 'EUR',
			add: {
				products: cartProducts
			}
		},
		google_tag_params: {
			ecomm_pagetype: "category",
			ecomm_prodid: productIds,
			ecomm_totalvalue: cartValue
		},
	}

	pushEcommerceEvent(event)
}


export function sendComboProductsRemoveFromCart(products){
	if(!products.length) return

	const groupedProducts = groupBy(products, 'id')

	const cartProducts = map(groupedProducts, arr => {
		const product = arr[0]
		const quantity = arr.length

		return {
			id: product.id,
			name: product.name,
			category: product.category_name,
			price: product.price,
			brand,
			quantity
		}
	})

	const productIds = map(cartProducts, 'id')
	const cartValue = calcTotalValue(cartProducts)

	const event = {
		event: 'removeFromCart',
		ecommerce: {
			remove: {
				products: cartProducts
			}
		},
		google_tag_params: {
			ecomm_pagetype: "category",
			ecomm_prodid: productIds,
			ecomm_totalvalue: cartValue
		},
	}

	pushEcommerceEvent(event)
}


export function sendCheckout(products, step, option){
	if(!(products && products.length)) return

	const groupedProducts = groupBy(products, 'id')

	const checkoutProducts = map(groupedProducts, arr => {
		const product = arr[0]
		const quantity = arr.length

		return {
			id: product.id,
			name: product.name,
			category: product.category_name,
			price: product.price,
			brand,
			quantity
		}
	})

	const productIds = map(checkoutProducts, 'id')
	const cartValue = calcTotalValue(checkoutProducts)

	const event = {
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: {
					step,
					option
				},
				products: checkoutProducts
			}
		},
		google_tag_params: {
			ecomm_pagetype: "cart",
			ecomm_prodid: productIds,
			ecomm_totalvalue: cartValue
		},
	}

	pushEcommerceEvent(event)
}


export function sendPurchase(subscription, user){
	if(!get(subscription, 'productsPerWeek.length')) return

	const allProducts = flatten(map(subscription.productsPerWeek, 'products'))

	// filter out delivery fees
	remove(allProducts, p => {
		return p.id === -1
	})

	if(!allProducts.length) return

	const groupedProducts = groupBy(allProducts, 'id')

	const purchaseProducts = map(groupedProducts, arr => {
		const product = arr[0]
		const quantity = arr.length

		return {
			id: product.id,
			name: product.name,
			price: Math.round(product.price * 100) / 100,
			category: product.category_name,
			brand,
			quantity
		}
	})

	const id = subscription.transaction_id
		|| get(subscription, 'chargeResponse.CustomerGenericTransaction.id')

	const tax = Math.round(subscription.price * 13) / 100

	const coupon = subscription.type + '_plan_discount_' + subscription.discount_percentage

	const productIds = map(purchaseProducts, 'id')

	const event = {
		event: 'purchase',
		ecommerce: {
			purchase: {
				actionField: {
					id,
					affiliation: 'Online Store',
					revenue: subscription.price,
					tax,
					shipping: subscription.delivery_price,
					coupon
				},
				products: purchaseProducts
			}
		},
		email: user.email,
		phone_number: user.phone,
		google_tag_params: {
			ecomm_pagetype: "purchase",
			ecomm_prodid: productIds,
			ecomm_totalvalue: subscription.sub_price
		},
	}

	pushEcommerceEvent(event)
}



export function tagHomeView(){
	const event = {
		event: 'fireRemarketingTag',
		google_tag_params: {
			ecomm_pagetype: 'home',
		}
	}

	pushFireRemarketingTagEvent(event)
}


export function tagProductView(product){
	const event = {
		event: 'fireRemarketingTag',
		google_tag_params: {
			ecomm_prodid: product.id,
			ecomm_pagetype: 'product',
			ecomm_totalvalue: product.price,
			ecomm_category: product.category_name
		}
	}

	pushFireRemarketingTagEvent(event)
}


export function tagCartView(products){
	const porductIds = map(products, 'id')
	let productsValue = sumBy(products, 'price')
	productsValue = Math.round(productsValue * 100) / 100

	const event = {
		event: 'fireRemarketingTag',
		google_tag_params: {
			ecomm_prodid: porductIds,
			ecomm_pagetype: 'cart',
			ecomm_totalvalue: productsValue
		}
	}

	pushFireRemarketingTagEvent(event)
}


export function tagCheckoutView(products){
	const porductIds = map(products, 'id')
	let productsValue = sumBy(products, 'price')
	productsValue = Math.round(productsValue * 100) / 100

	const event = {
		event: 'fireRemarketingTag',
		google_tag_params: {
			ecomm_prodid: porductIds,
			ecomm_pagetype: 'cart',
			ecomm_totalvalue: productsValue
		}
	}

	pushFireRemarketingTagEvent(event)
}


export function tagConfirmationView(subscription){
	const allProducts = flatten(map(subscription.productsPerWeek, 'products'))

	// filter out delivery fees
	remove(allProducts, p => {
		return p.id === -1
	})

	const porductIds = uniq(map(allProducts, 'id'))

	let productsValue = sumBy(allProducts, 'price')
	productsValue = Math.round(productsValue * 100) / 100

	const event = {
		event: 'fireRemarketingTag',
		google_tag_params: {
			ecomm_prodid: porductIds,
			ecomm_pagetype: 'purchase',
			ecomm_totalvalue: productsValue
		}
	}

	pushFireRemarketingTagEvent(event, true)
}
