'use strict'

const bent = require('bent')
const { API_KEY } = require('./auth')

const API_URL = process.env.VUE_APP_API_URL

const headers = {
	'APIKEY': API_KEY
}

const statusCodes = [200, 400, 401, 403, 404, 422, 423, 500]

const getJSON = bent(API_URL, 'json', statusCodes, headers)
const postJSON = bent(API_URL, 'POST', 'json', statusCodes, headers)


export function fetchPostalCodes(){
	return getJSON('DeliveryPostalCodes')
}

export function fetchClosedDeliveryDates(){
	return getJSON('ClosedDeliveryDates')
}

export function fetchCombos(){
	return getJSON('ProductCombo')
}

export function fetchComboDetails(comboID){
	return getJSON('ProductCombo/' + comboID + '/details')
}

export function fetchProducts(){
	return getJSON('Product')
}

export function fetchOneProduct(productID){
	return getJSON('Product/' + productID)
}

export function fetchDeliveryFees(){
	return getJSON('delivery_fees')
}

export function payOffer(data){
	// const queryStr = "?token=" + token
	return postJSON('payment_submit', data)
}

export async function userLogin(data){
	return postJSON('login', data)
}

export async function userSignup(data){
	return postJSON('signup', data)
}
export async function userSignupEvent(data){
	return postJSON("signup_event/" + data.id, data)
}

export async function userForgotPassword(data){
	return postJSON('forgot_password', data)
}

export async function userResetPassword(data){
	return postJSON('reset_password', data)
}

export function fetchOffer(token){
	const queryStr = "?token=" + token
	return getJSON('user/payment_overview' + queryStr)
}
export async function  userGetJSON(userID, queryToken){
	const headers = {
		'APIKEY': API_KEY,
		'Authorization': 'Bearer ' + queryToken
	}
	const userGetRequest = bent(API_URL + 'user/', 'json', statusCodes, headers)
	const url = userID + '/fetch'
	return await userGetRequest(url)
}

export function userJSON({ accessToken, queryToken }){
	const USER_API_URL = API_URL + 'user/'

	const headers = {
		'APIKEY': API_KEY
	}

	if(accessToken){
		headers['Authorization'] = 'Bearer ' + accessToken
	}

	const queryString = queryToken ? '?token=' + queryToken : ''

	const userGetRequest = bent(USER_API_URL, 'json', statusCodes, headers)
	const userPostRequest = bent(USER_API_URL, 'POST', 'json', statusCodes, headers)
	const userPutRequest = bent(USER_API_URL, 'PUT', 'json', statusCodes, headers)
	const userDeleteRequest = bent(USER_API_URL, 'DELETE', 'json', statusCodes, headers)

	return {
		async fetchDiscount(userID){
			const url = userID + '/fetch' + queryString
			return userGetRequest(url)
		},
		async fetchAddresses(userID){
			const url = queryString
				? 'CustomerAddresses' + queryString + '&where=isactive=true AND customer_id=' + userID
				: 'CustomerAddresses?where=isactive=true AND customer_id=' + userID

			return userGetRequest(url)
		},
		async createAddress(address){
			const url = 'address'+ queryString
			return userPostRequest(url, address)
		},
		async updateAddress(address){
			const url = 'address/' + address.id + queryString
			return userPutRequest(url, address)
		},
		async fetchCreditCards(userID){
			const url = queryString
				? 'CustomerCreditCards' + queryString + '&where=active=1 AND customer_id=' + userID
				: 'CustomerCreditCards?where=active=1 AND customer_id=' + userID

			return userGetRequest(url)
		},
		async submitOrder(data, userID){
			const url = userID + '/subscription' + queryString
			return userPostRequest(url, data)
		},
		async updateSubscription(subscription, userID){
			const url = userID + '/subscription/' + subscription.id + queryString
			return userPutRequest(url, subscription)
		},
		async updateSubscriptionStatus(subscription, userID){
			const url = userID + '/subscription/status/' + subscription.id + queryString
			return userPutRequest(url, subscription)
		},
		async chargeCard(data){
			const url = 'payment' + queryString
			return userPostRequest(url, data)
		},
		async orderCompleteEmail(data){
			const url = 'emails/order_complete' + queryString
			return userPostRequest(url, data)
		},
		async addCreditCard(data, userID){
			const url = userID + '/add_credit_card' + queryString
			return userPostRequest(url, data)
		},
		async newCreditCard(userID){
			const url = userID + '/add_credit_card_viva' + queryString
			return userPostRequest(url, {})
		},
		async setDefaultCreditCard(cardID, userID){
			const url = userID + '/set_default_card/' + cardID + queryString
			return userPutRequest(url, {})
		},
		async deleteCreditCard(cardID, userID){
			const url = userID + '/delete_credit_card/' + cardID + queryString
			return userDeleteRequest(url)
		},
		async updateProfile(data, userID){
			const url = 'update/' + userID + queryString
			return userPutRequest(url, data)
		},
		async changePassword(data, userID){
			const url = userID + '/change_password' + queryString
			return userPutRequest(url, data)
		},
		async fetchSubscriptions(userID, limit, page){
			const query = {
				order: 'next_delivery_date DESC, created_at DESC'
			}
			if(queryString){
				const arr = queryString.split('=')
				query.token = arr[1]
			}
			if(limit) query.limit = limit
			if(page) query.page = page

			const queryParams = new URLSearchParams(query)
			let queryStr = queryParams.toString()
			if(queryStr) queryStr = '?' + queryStr

			const url = userID + '/subscriptions' + queryStr
			return userGetRequest(url)
		},
		async fetchSubscriptionDetails(userID, subscriptionID){
			const url = userID + '/subscriptions/' + subscriptionID + queryString
			return userGetRequest(url)
		},
		async fetchPayments(userID, query){
			if(queryString){
				query.token = queryString.split('=').pop()
			}

			const queryParams = new URLSearchParams(query)
			let queryStr = queryParams.toString()
			if(queryStr) queryStr = '?' + queryStr

			const url = userID + '/transactions' + queryStr
			return userGetRequest(url)
		},
		async searchCoupon(userID, data){
			const url = userID + '/coupon/search' + queryString
			return userPostRequest(url, data)
		},
		async consumeCoupon(userID, data){
			const url = userID + '/coupon/consume' + queryString
			return userPostRequest(url, data)
		}
	}
}
