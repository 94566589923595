'use strict'

var CryptoJS = require('crypto-js')

const password = '0233a2cb0ff43e5&9e$41V499e591f880f36$'
const landingPassword = 'reY5d-G!2@F'

const dbKey = process.env.VUE_APP_DB_KEY


export function encrypt(text){
	return CryptoJS.AES.encrypt(text, password).toString()
}

export function decrypt(hexString){
	const bytes = CryptoJS.AES.decrypt(hexString, password)
	return bytes.toString(CryptoJS.enc.Utf8)
}

export function decryptLanded(hexString){
	const buff = Buffer(hexString, 'base64').toString('utf-8')
	const bytes = CryptoJS.AES.decrypt(buff, landingPassword)
	return bytes.toString(CryptoJS.enc.Utf8)
}

export const API_KEY = encrypt(dbKey)
