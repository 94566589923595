'use strict'

export default {

	adhoc: [
		{
			no: 1,
			name: 'Βήμα 1',
			section: '',
			pageTitle: 'Check Postal Code',
			leftAriaTitle: 'Check if your postal code is supported',
			rightAriaTitle: 'Step 1 of 7',
			leftComponent: 'CheckPostalCodeLeft',
			rightComponent: 'CheckPostalCodeRight',
			subState: 'PostalCode'
		},
		{
			no: 2,
			name: 'Βήμα 2',
			section: 'plan',
			pageTitle: 'Select Order',
			leftAriaTitle: 'Select an order or a subscription',
			rightAriaTitle: 'Step 2 of 7',
			leftComponent: 'OrderTypeSelectLeft',
			rightComponent: 'OrderTypeSelectRight',
			subState: 'OrderType'
		},
		{
			no: 3,
			name: 'Βήμα 3',
			section: 'deliveries',
			pageTitle: 'Select Delivery Days',
			leftAriaTitle: 'Select Delivery Days',
			rightAriaTitle: 'Step 3 of 7',
			leftComponent: 'SelectDeliveriesLeft',
			rightComponent: 'SelectDeliveriesRight',
			subState: 'DeliveryDays'
		},
		{
			no: 4,
			name: 'Βήμα 4',
			section: 'meals',
			pageTitle: 'Select Meals',
			leftAriaTitle: 'Select Meals',
			rightAriaTitle: 'Step 4 of 7',
			leftComponent: 'SelectMealsLeft',
			rightComponent: 'SelectMealsRight',
			subState: 'MealsFirst'
		},
		{
			no: 5,
			name: 'Βήμα 5',
			section: 'checkout',
			pageTitle: 'Log In or Sign Up',
			leftAriaTitle: 'Log In or Sign Up',
			rightAriaTitle: 'Step 5 of 7',
			leftComponent: 'OrderSumup',
			rightComponent: 'LoginOrSignup',
			subState: 'LoginOrSignup'
		},
		{
			no: 6,
			name: 'Βήμα 6',
			section: 'checkout',
			pageTitle: 'Choose Address or Add A New One',
			leftAriaTitle: 'Choose Address or Add A New One',
			rightAriaTitle: 'Step 6 of 7',
			leftComponent: 'OrderSumup',
			rightComponent: 'ChooseAddress',
			subState: 'Addresses'
		},
		{
			no: 7,
			name: 'Βήμα 7',
			section: 'checkout',
			pageTitle: 'Choose Payment Method',
			leftAriaTitle: 'Choose Payment Method',
			rightAriaTitle: 'Step 7 of 7',
			leftComponent: 'OrderSumup',
			rightComponent: 'ChoosePaymentMethod',
			subState: 'PaymentMethod'
		}
	],

	weekly: [
		{
			no: 1,
			name: 'Βήμα 1',
			section: '',
			pageTitle: 'Check Postal Code',
			leftAriaTitle: 'Check if your postal code is supported',
			rightAriaTitle: 'Step 1 of 10',
			leftComponent: 'CheckPostalCodeLeft',
			rightComponent: 'CheckPostalCodeRight',
			subState: 'PostalCode'
		},
		{
			no: 2,
			name: 'Βήμα 2',
			section: 'plan',
			pageTitle: 'Select Order',
			leftAriaTitle: 'Select an order or a weekly subscription',
			rightAriaTitle: 'Step 2 of 10',
			leftComponent: 'OrderTypeSelectLeft',
			rightComponent: 'OrderTypeSelectRight',
			subState: 'OrderType'
		},
		{
			no: 3,
			name: 'Βήμα 3',
			section: 'plan',
			pageTitle: 'Choose Plan',
			leftAriaTitle: 'Choose Plan',
			rightAriaTitle: 'Step 3 of 10',
			leftComponent: 'ChoosePlanLeft',
			rightComponent: 'ChoosePlanRight',
			subState: 'Plan'
		},
		{
			no: 4,
			name: 'Βήμα 4',
			section: 'deliveries',
			pageTitle: 'Select Delivery Days',
			leftAriaTitle: 'Select Delivery Days',
			rightAriaTitle: 'Step 4 of 10',
			leftComponent: 'SelectDeliveriesLeft',
			rightComponent: 'SelectDeliveriesRight',
			subState: 'DeliveryDays'
		},
		{
			no: 5,
			name: 'Βήμα 5',
			section: 'meals',
			pageTitle: 'Select Product Combo',
			leftAriaTitle: 'Select Product Combo',
			rightAriaTitle: 'Step 5 of 10',
			leftComponent: 'SelectComboLeft',
			rightComponent: 'SelectComboRight',
			subState: 'Combo'
		},
		{
			no: 6,
			name: 'Βήμα 6',
			section: 'meals',
			pageTitle: 'Select Meals',
			leftAriaTitle: 'Select Meals',
			rightAriaTitle: 'Step 6 of 10',
			leftComponent: 'SelectMealsLeft',
			rightComponent: 'SelectMealsRight',
			subState: 'MealsFirst'
		},
		{
			no: 7,
			name: 'Βήμα 7',
			section: 'meals',
			pageTitle: 'Select Meals',
			leftAriaTitle: 'Select Meals',
			rightAriaTitle: 'Step 7 of 10',
			leftComponent: 'SelectMealsLeft',
			rightComponent: 'SelectMealsRight',
			subState: 'MealsSecond'
		},
		{
			no: 8,
			name: 'Βήμα 8',
			section: 'checkout',
			pageTitle: 'Log In or Sign Up',
			leftAriaTitle: 'Log In or Sign Up',
			rightAriaTitle: 'Step 8 of 10',
			leftComponent: 'OrderSumup',
			rightComponent: 'LoginOrSignup',
			subState: 'LoginOrSignup'
		},
		{
			no: 9,
			name: 'Βήμα 9',
			section: 'checkout',
			pageTitle: 'Choose Address or Add A New One',
			leftAriaTitle: 'Choose Address or Add A New One',
			rightAriaTitle: 'Step 9 of 10',
			leftComponent: 'OrderSumup',
			rightComponent: 'ChooseAddress',
			subState: 'Addresses'
		},
		{
			no: 10,
			name: 'Βήμα 10',
			section: 'checkout',
			pageTitle: 'Choose Payment Method',
			leftAriaTitle: 'Choose Payment Method',
			rightAriaTitle: 'Step 10 of 10',
			leftComponent: 'OrderSumup',
			rightComponent: 'ChoosePaymentMethod',
			subState: 'PaymentMethod'
		},
	],

	monthly: [
		{
			no: 1,
			name: 'Βήμα 1',
			section: '',
			pageTitle: 'Check Postal Code',
			leftAriaTitle: 'Check if your postal code is supported',
			rightAriaTitle: 'Step 1 of 9',
			leftComponent: 'CheckPostalCodeLeft',
			rightComponent: 'CheckPostalCodeRight',
			subState: 'PostalCode'
		},
		{
			no: 2,
			name: 'Βήμα 2',
			section: 'plan',
			pageTitle: 'Select Order',
			leftAriaTitle: 'Select an order or a monthly subscription',
			rightAriaTitle: 'Step 2 of 9',
			leftComponent: 'OrderTypeSelectLeft',
			rightComponent: 'OrderTypeSelectRight',
			subState: 'OrderType'
		},
		{
			no: 3,
			name: 'Βήμα 3',
			section: 'deliveries',
			pageTitle: 'Select Delivery Days',
			leftAriaTitle: 'Select Delivery Days',
			rightAriaTitle: 'Step 3 of 9',
			leftComponent: 'SelectDeliveriesLeft',
			rightComponent: 'SelectDeliveriesRight',
			subState: 'DeliveryDays'
		},
		{
			no: 4,
			name: 'Βήμα 4',
			section: 'meals',
			pageTitle: 'Select Product Combo',
			leftAriaTitle: 'Select Product Combo',
			rightAriaTitle: 'Step 4 of 9',
			leftComponent: 'SelectComboLeft',
			rightComponent: 'SelectComboRight',
			subState: 'Combo'
		},
		{
			no: 5,
			name: 'Βήμα 5',
			section: 'meals',
			pageTitle: 'Select Meals',
			leftAriaTitle: 'Select Meals',
			rightAriaTitle: 'Step 5 of 9',
			leftComponent: 'SelectMealsLeft',
			rightComponent: 'SelectMealsRight',
			subState: 'MealsFirst'
		},
		{
			no: 6,
			name: 'Βήμα 6',
			section: 'meals',
			pageTitle: 'Select Meals',
			leftAriaTitle: 'Select Meals',
			rightAriaTitle: 'Step 6 of 9',
			leftComponent: 'SelectMealsLeft',
			rightComponent: 'SelectMealsRight',
			subState: 'MealsSecond'
		},
		{
			no: 7,
			name: 'Βήμα 7',
			section: 'checkout',
			pageTitle: 'Log In or Sign Up',
			leftAriaTitle: 'Log In or Sign Up',
			rightAriaTitle: 'Step 7 of 9',
			leftComponent: 'OrderSumup',
			rightComponent: 'LoginOrSignup',
			subState: 'LoginOrSignup'
		},
		{
			no: 8,
			name: 'Βήμα 8',
			section: 'checkout',
			pageTitle: 'Choose Address or Add A New One',
			leftAriaTitle: 'Choose Address or Add A New One',
			rightAriaTitle: 'Step 8 of 9',
			leftComponent: 'OrderSumup',
			rightComponent: 'ChooseAddress',
			subState: 'Addresses'
		},
		{
			no: 9,
			name: 'Βήμα 9',
			section: 'checkout',
			pageTitle: 'Choose Payment Method',
			leftAriaTitle: 'Choose Payment Method',
			rightAriaTitle: 'Step 9 of 9',
			leftComponent: 'OrderSumup',
			rightComponent: 'ChoosePaymentMethod',
			subState: 'PaymentMethod'
		}
	]
}

