import { encrypt, decrypt } from './auth'

import {
	concat,
	find,
	get,
	isInteger,
	isNil,
	last,
	now,
	random,
	remove,
	sortBy,
	sumBy
} from 'lodash'

import { DateTime } from 'luxon'

const googleMapsAPIKey = 'AIzaSyCS0pyTw_jCD0l3F40QUEs2xytHnXiPHCY'


export function arrayOfIntegers(first, length){
	if(!(isInteger(first) && isInteger(length))){
		throw new Error('One or both arguments are invalid')
	}

	const arr = []
	let i = first
	do {
		arr.push(i++)
	} while (arr.length < length)

	return arr
}

export function formatPrice(price, decimals){
	const decDigits = isInteger(decimals) ? decimals : 2
	const priceNumber = isNil(price) ? 0 : price
	return priceNumber.toFixed(decDigits) + '\u20AC';
}

export function parsePrice(price){
	const priceNum = price.split('\u20AC').shift()
	return Number.parseFloat(priceNum)
}

export function calcDiscountedPrice(price, discount){
	return price * (100 - discount) / 100
}

export function calcPriceBeforeDiscount(discountedPrice, discount){
	return discountedPrice * 100 / (100 - discount)
}

export function calcMealsEnvironmentTaxCost(meals){
	return sumBy(meals, m => {
		return (m.quantity || 1) * m.environment_tax_price
	})
}

export function weekdayToNumber(weekday){
	if(!weekday) return null
	if(typeof weekday === 'number') return weekday
	return DateTime.fromFormat(weekday, 'EEEE').weekday
}

export function weekdayToString(weekday){
	if(!weekday) return ""
	return DateTime.now().set({ weekday }).toFormat('EEEE').toLowerCase()
}

export function numberToLetter(number){
	switch(number){
		case 1: return 'A\''
		case 2: return 'Β\''
		case 3: return 'Γ\''
		case 4: return 'Δ\''
		case 5: return 'Ε\''
		case 6: return 'ΣΤ\''
		case 7: return 'Η\''
		case 8: return 'Θ\''
		case 9: return 'Ι\''
	}
}

export function formatAddressLabel(address){
	return address.friendly_name
		? address.friendly_name + ' - ' + address.address_street
		: address.address_street
}

export function formatAddressUserLabel(address){
	return address.address_street + ', ' + address.address_city
}

export function formatCreditCardLabel(card){
	// return card.type + ' - *****' + card.number
	return card.type + ' - ' + card.number
}

export function hasNumber(string){
	return string.match(/\d/)
}

export function isValidCardDate(string){
	let [month, year] = string.split('/')

	if(!(month && year)) return false
	if(month.length !== 2 || year.length !== 2) return false

	month = Number.parseInt(month)
	year = Number.parseInt(year)

	if(Number.isNaN(month) || month > 12 || month < 1) return false
	if(Number.isNaN(year) || year > 100 || year < 0) return false

	const now = DateTime.now()
	const currentYear = now.year
	const currentAge = currentYear.toString().substring(0, 2)
	let expYear = year < 10 ? currentAge + "0" + year : currentAge + year
	expYear = Number.parseInt(expYear)
	const expDate = DateTime.fromObject({ year: expYear, month: month }).endOf('month')

	return now.toMillis() <= expDate.toMillis()
}

export function isCVC(string){
	if(string.length < 3 || string.length > 4) return false

	if(Number.isNaN(Number.parseInt(string))) return false

	return true
}

export function setStorageItem(key, value){
	const inDomainKey = 'fitnessMeals.' + key

	if(value){
		const str = JSON.stringify({ value })
		const encryptedStr = encrypt(str)
		window.localStorage.setItem(inDomainKey, encryptedStr)
	} else {
		window.localStorage.removeItem(inDomainKey)
	}
}

export function getStorageItem(key){
	const inDomainKey = 'fitnessMeals.' + key
	const encryptedStr = window.localStorage.getItem(inDomainKey)
	const str = encryptedStr ? decrypt(encryptedStr) : null
	return get(JSON.parse(str), 'value')
}

export function attachGoogleScript(){
	if(!(window.google && window.google.maps)){
		const script = document.createElement('script');
		script.src = 'https://maps.googleapis.com/maps/api/js?key='
			+ googleMapsAPIKey
			+ '&libraries=places'

		document.head.appendChild(script)
	}
}

export function attachIframeResizerScript(){
	const script = document.createElement('script')
	script.type = 'text/javascript'
	script.src = '../src/assets/iframeResizer.contentWindow.min.js'
	document.head.appendChild(script)
}

export function initHtmlProperties(){
	const [html] = document.getElementsByTagName('html')
	html.setAttribute('lang', 'el')
}


export function setMealSortId(state, meal){
	if(state.selectedMeals.length){
		const sameExistingMeal = find(state.selectedMeals, ['id', meal.id])
		if(sameExistingMeal){
			// if the same meal is already selected,
			// assign the same sortId
			return sameExistingMeal.sortId
		} else {
			// otherwise, find the biggest existing sortId
			// and assign the next one
			const sortedMeals = sortBy(state.selectedMeals, ['sortId'])
			return get(last(sortedMeals), 'sortId') + 1
		}
	} else {
		// this is the first selected meal,
		// so it has the smallest sortId
		return 1
	}
}


export function replaceDeliveryDaysClosedOptions(deliveryDays, closedDays, allowedWeekdays){
	const lastDayOption = last(deliveryDays)
	const closedOptions = remove(deliveryDays, d => {
		return allowedWeekdays.indexOf(d.weekday) === -1
				|| find(closedDays, ['timestamp', d.toUTC().valueOf()])
	})

	if(closedOptions.length){
		const daysAfterLastOption = arrayOfIntegers(1, closedOptions.length).map(d => {
			return lastDayOption.plus({ days: d })
		})

		const extendedDeliveryDays = concat(deliveryDays, daysAfterLastOption)
		return replaceDeliveryDaysClosedOptions(
			extendedDeliveryDays,
			closedDays,
			allowedWeekdays
		)
	}

	return deliveryDays
}


/**
 * Generates an RFC 4122 version 4 uuid
 *
 * @see http://stackoverflow.com/a/8809472
 * @returns {String} the generated uuid
 */
export function uuid(){
	let d = now()
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c){
		const r = (d + random(16)) % 16 | 0
		d = Math.floor(d / 16)
		return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
	})
}
