import {
	concat,
	filter,
	find,
	flatMap,
	get,
	groupBy,
	isString,
	last,
	map,
	pick,
	remove,
	round,
	sortBy,
	sumBy,
	uniqBy,
	without
} from 'lodash'

import { DateTime } from 'luxon'

import {
	calcDiscountedPrice,
	calcMealsEnvironmentTaxCost,
	formatPrice,
	weekdayToNumber,
	weekdayToString
} from './utils'

const weekDateFormat = "dd-MM-yyyy"


export function defineProductDeliveryWeekday(deliveryDays, productConsumptionWeekday){
	if(!get(deliveryDays, 'length')) return

	const sortedDDays = sortBy(deliveryDays, ['weekday'])

	const pcWeekdayNum = isString(productConsumptionWeekday)
		? weekdayToNumber(productConsumptionWeekday)
		: productConsumptionWeekday

	// if product consumption day is earlier than first selected delivery day
	// assign it to last delivery day
	if(pcWeekdayNum < sortedDDays[0].weekday){
		return get(last(sortedDDays), 'weekday')
	}

	// otherwise return the last delivery day which
	// is the same or earlier than product consumption day
	var productDeliveryDay = find(sortedDDays, (dd, i, ddays) => {
		if(i < ddays.length - 1) return pcWeekdayNum < ddays[i+1].weekday
		else return true
	});
	return productDeliveryDay.weekday
}


export function setWeeklyComboMeals(comboMeals, state, getters){
	return map(comboMeals, meal => {
		const formattedPrice = formatPrice(meal.price)
		const discountedPrice = calcDiscountedPrice(meal.price, getters.maxDiscount)
		const formattedDiscountedPrice = formatPrice(discountedPrice)

		const deliveryWeekday = defineProductDeliveryWeekday(state.deliveryDates, meal.consumption_weekday)
		const deliveryDate = find(state.deliveryDates, ['weekday', deliveryWeekday])
		const deliveryTs = deliveryDate.timestamp

		return {
			...meal,
			deliveryWeekday,
			deliveryTs,
			discountedPrice,
			formattedDiscountedPrice,
			formattedPrice
		}
	})
}


export function setMonthlyComboMeals(comboMeals, state, getters){
	const groupedMealsByWeek = groupBy(comboMeals, 'consumption_week')

	return flatMap(groupedMealsByWeek, (weekMeals, week) => {
		// select two delivery dates for each delivery week
		// for users in close range locations,
		// and one delivery date per delivery week
		// for users in far range locations
		// Increment can be equal to 2 or 1 repsectively
		const increment = state.deliveryDates.length / 4
		const firstDateIndex = increment * (week - 1)
		const sliceEndIndex = firstDateIndex + increment
		const weekDeliveryDates = state.deliveryDates.slice(firstDateIndex, sliceEndIndex)

		return map(weekMeals, meal => {
			const formattedPrice = formatPrice(meal.price)
			const discountedPrice = calcDiscountedPrice(meal.price, getters.maxDiscount)
			const formattedDiscountedPrice = formatPrice(discountedPrice)

			const deliveryWeekday = defineProductDeliveryWeekday(weekDeliveryDates, meal.consumption_weekday)
			const deliveryDate = find(weekDeliveryDates, ['weekday', deliveryWeekday])
			const deliveryTs = deliveryDate.timestamp

			return {
				...meal,
				deliveryWeekday,
				deliveryTs,
				discountedPrice,
				formattedDiscountedPrice,
				formattedPrice
			}
		})
	})
}


export function calcDeliveryDaysMealCount(meals){
	const groupedMeals = groupBy(meals, 'consumption_week')

	return map(groupedMeals, (arr, week) => {
		const mealsByDeliveryDay = groupBy(arr, 'deliveryWeekday')
		const mealsCountPerDay = map(mealsByDeliveryDay, (a, day) => {
			return {
				deliveryWeekday: Number.parseInt(day),
				mealsCount: a.length
			}
		})

		return {
			week,
			mealsCountPerDay
		}
	})
}


export function getDateWeekPeriod(date){
	const jsDate = date ? new Date(date) : new Date()
	const refDateTime = DateTime.fromJSDate(jsDate)

	return {
		date: date,
		start: refDateTime.startOf('week').toFormat(weekDateFormat),
		startTs: refDateTime.startOf('week').toMillis(),
		end: refDateTime.endOf('week').toFormat(weekDateFormat),
		endTs: refDateTime.endOf('week').toMillis()
	}
}


export function getDateWeekday(date){
	const jsDate = date ? new Date(date) : new Date()
	const refDateTime = DateTime.fromJSDate(jsDate)

	return refDateTime.setLocale('en').weekdayLong.toLowerCase()
}


export function generateDeliveryDays(deliveryDates, deliveryTime){
	return map(deliveryDates, dd => {
		const { address, timestamp, take_away } = dd
		const refDateTime = DateTime.fromMillis(timestamp)
		const weekday = refDateTime.setLocale('en').weekdayLong.toLowerCase()
		const timeframe = deliveryTime.label
		return { address, weekday, timeframe, take_away }
	})
}


export function generateWeekDeliveryFees(fee, deliveryDays, products, freeDelivery, inCloseRange){
	const feeProps = pick(fee, ['id', 'name', 'price'])
	const unit_price = feeProps.price

	const productsPerWeekday = groupBy(products, 'delivery_weekday')
	// delivery fee without delivery_date patch
	if(Object.keys(productsPerWeekday).indexOf("undefined") > -1){
		delete productsPerWeekday.undefined
	}

	const fees = map(productsPerWeekday, (arr, weekday) => {
		// do not return fee object if delivery day address is take-away
		const dday = find(deliveryDays, dd => {
			return dd.weekday === weekday || dd.weekday == weekdayToNumber(weekday)
		})
		if(dday && dday.take_away) return null

		const productsPrice = sumBy(arr, 'price')
		const discount = (inCloseRange && (freeDelivery || productsPrice >= 50)) ? 100 : 0
		const price = round(unit_price * (1 - (discount / 100)))
		return {
			...feeProps,
			delivery_weekday: weekday,
			discount,
			price,
			unit_price
		}
	})

	return without(fees, null)
}


export function generateSubscriptionWeeks(state, getters){
	const dDates = state.deliveryDates
	let weeks = map(dDates, d => {
		const period = getDateWeekPeriod(d.timestamp)
		return period
	})

	weeks = uniqBy(weeks, 'start')

	return map(weeks, w => {
		const weekDeliveryDates = filter(dDates, dd => {
			return dd.timestamp >= w.startTs && dd.timestamp < w.endTs
		})
		const delivery_days = generateDeliveryDays(weekDeliveryDates, state.deliveryTime)

		let products = filter(state.selectedMeals, meal => {
			return meal.deliveryTs >= w.startTs && meal.deliveryTs < w.endTs
		})

		products = map(products, meal => {
			const product = pick(meal, ['id', 'name', 'consumption_weekday', 'environment_tax_price'])
			const unit_price = meal.price
			const discount = getters.maxDiscount
			const price = calcDiscountedPrice(unit_price, discount)
			const deliveryDay = find(delivery_days, dd => {
				return weekdayToNumber(dd.weekday) === meal.deliveryWeekday
			})
			const delivery_weekday = deliveryDay.weekday

			return {
				...product,
				unit_price,
				discount,
				price,
				delivery_weekday
			}
		})

		const fee = getters.deliveryFee
		const freeDelivery = state.selectedCombo.free_delivery
		const inCloseRange = getters.userInCloseRange
		const deliveryFees = generateWeekDeliveryFees(
			fee,
			delivery_days,
			products,
			freeDelivery,
			inCloseRange
		)

		return {
			period: w,
			delivery_days,
			products: concat(products, deliveryFees)
		}
	})
}


export function formatAdhocSubscription(state, getters){
	const delivery_days = generateDeliveryDays(state.deliveryDates, state.deliveryTime)
	const deliveryStartTs = state.deliveryStart.timestamp
	const period = getDateWeekPeriod(deliveryStartTs)
	const discount = getters.maxDiscount

	const products = map(state.selectedMeals, (meal) => {
		const product = pick(meal, ['id', 'name', 'price', 'environment_tax_price'])
		const unit_price = product.price
		const price = calcDiscountedPrice(unit_price, discount)
		const delivery_weekday = delivery_days[0].weekday
		return {
			...product,
			unit_price,
			discount,
			price,
			delivery_weekday
		}
	})

	const environmentTaxPrice = calcMealsEnvironmentTaxCost(products)

	const fee = getters.deliveryFee
	const inCloseRange = getters.userInCloseRange
	const deliveryFees = generateWeekDeliveryFees(
		fee,
		delivery_days,
		products,
		false,
		inCloseRange
	)

	const subscription = {
		custom_subscription: true,
		friends_and_family: state.user.friends_and_family,
		payment_type: state.paymentMethod,
		productsPerWeek: [{
			period,
			products: concat(products, deliveryFees),
			delivery_days,
		}],
		status: state.paymentMethod === 'card' ? 'uncompleted' : 'active',
		type: state.orderType,
		channel: 'eshop',
		discount_percentage: discount,
		sub_price: Math.round( sumBy(products, 'price') * 100) / 100,
		delivery_price: Math.round(sumBy(deliveryFees, 'price') * 100) / 100,
		free_delivery: getters.userInCloseRange
			? state.selectedCombo.free_delivery
			: 0
	}
	let price = subscription.sub_price + subscription.delivery_price + environmentTaxPrice
	subscription.price = Math.round(price * 100) / 100
	return subscription
}


export function formatSubscription(state, getters){
	const product_combo_id = state.selectedCombo.id !== 'custom'
		? state.selectedCombo.id
		: null

	const weeks = generateSubscriptionWeeks(state, getters)

	const allProducts = flatMap(weeks, 'products')
	const deliveryFees = remove(allProducts, p => p.id < 0)

	const environmentTaxPrice = calcMealsEnvironmentTaxCost(allProducts)

	const subscription = {
		auto_renewal: true,
		custom_subscription: state.customSubscription,
		friends_and_family: state.user.friends_and_family,
		payment_type: state.paymentMethod,
		product_combo_id,
		productsPerWeek: weeks,
		status: state.paymentMethod === 'card' ? 'uncompleted' : 'active',
		type: state.orderType,
		channel: 'eshop',
		discount_percentage: getters.maxDiscount,
		sub_price: Math.round(sumBy(allProducts, 'price') * 100) / 100,
		delivery_price: Math.round(sumBy(deliveryFees, 'price') * 100) / 100,
		free_delivery: getters.userInCloseRange
			? state.selectedCombo.free_delivery
			: 0
	}

	let price = subscription.sub_price + subscription.delivery_price + environmentTaxPrice
	subscription.price = Math.round(price * 100) / 100;

	return subscription
}


export function recalcSubscriptionProps(subscription, fee, inCloseRange){
	// recalc delivery fees from scratch for each week
	const productsPerWeek = map(subscription.productsPerWeek, week => {
		const { delivery_days } = week
		let { products } = week

		remove(products, p => p.id < 0)

		products = map(products, p => {
			return {
				...p,
				delivery_weekday: DateTime.fromISO(p.delivery_date).toFormat('EEEE').toLowerCase()
			}
		})
		const freeDelivery = subscription.free_delivery
		const deliveryFees = generateWeekDeliveryFees(
			fee,
			delivery_days,
			products,
			freeDelivery,
			inCloseRange
		)

		week.products = concat(products, deliveryFees)
		week.delivery_days = map(week.delivery_days, dd => {
			return {
				...dd,
				weekday: typeof dd.weekday === 'number'
					? weekdayToString(dd.weekday)
					: dd.weekday,
				timeframe: dd.fromTime + '-' + dd.toTime
			}
		})
		return week
	})

	const allProducts = flatMap(productsPerWeek, 'products')
	const deliveryFees = remove(allProducts, p => p.id < 0)

	const sub_price =  Math.round( sumBy(allProducts, 'price') * 100) / 100
	const delivery_price =  Math.round( sumBy(deliveryFees, 'price') * 100) / 100
	const environmentTaxPrice = calcMealsEnvironmentTaxCost(allProducts)

	const price = sub_price + delivery_price + environmentTaxPrice

	return {
		...subscription,
		productsPerWeek,
		sub_price,
		delivery_price,
		price
	}
}


export function formatDataFromCharge(state, subscription_id){
	// Customer ID
	const customer_id = state.user.id
	// CreditCard ID
	const cc_id = state.creditCard.id
	const chargeData = {
		customer_id: customer_id,
		cc_id: cc_id,
		subscription_id: subscription_id
	}

	return chargeData
}