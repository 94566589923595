export const subscriptionStatusOptions = [
	{
		status: 'finished',
		label: 'Ολοκληρώθηκε',
		classes: 'bg-xblack-500 text-white border-2 border-xblack-200'
	},
	{
		status: 'pending_renewal',
		label: 'Εκκρεμεί Ανανέωση',
		classes: 'bg-yellow-100 text-yellow-700 border-2 border-yellow-400'
	},
	{
		status: 'active',
		label: 'Ενεργή',
		classes: 'bg-green-100 text-green-700 border-2 border-green-400'
	},
	{
		status: 'paused',
		label: 'Σε προσωρινή διακοπή',
		classes: 'bg-yellow-100 text-yellow-700 border-2 border-yellow-400'
	},
	{
		status: 'cancelled',
		label: 'Ακυρώθηκε',
		classes: 'bg-xblack-100 text-xblack-400 border-2 border-xblack-300'
	},
	{
		status: 'to_be_completed',
		label: 'Υπό ολοκλήρωση',
		classes: 'bg-yellow-100 text-yellow-700 border-2 border-yellow-400'
	}
]

export const takeAwayAddress = {
	id: '00000000-0000-0000-0000-000000000000',
	friendly_name: 'Take away',
	address_street: 'Αντιγόνης 95',
	address_zip: '104 42',
	label: 'Take away - Αντιγόνης 95',
	location: 'Antigonis 95, Athina 104 42, Greece',
	postal_code: '10442',
	isactive: true,
	notes: 'Παραλάβετε την παραγγελία σας από το κατάστημά μας'
}
